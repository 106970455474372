<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">{{ headerText }}</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <b-field
            label="Mulai"
            custom-class="is-small"
            :type="{'is-danger': errorMap.mulai}"
            :message="errorMap.mulai"
          >
            <b-datepicker
                v-model="angkatan.mulai"
                @input="validateInput('mulai')"
                type="month"
                :mobile-native="false"
                :month-names="month_names"
                placeholder="Click to select..."
                icon="calendar-day"
                trap-focus>
            </b-datepicker>
            <!-- <date-picker v-model="angkatan.mulai" @input="validateInput('mulai')"></date-picker> -->
          </b-field>
          <b-field class="field-spacer"></b-field>
          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Angkatan from "../models/angkatan.js";


const MONTH_NAMES = [
  "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", 
  "Agustus", "September", "Oktober", "November", "Desember"];

export default {
  name: "AngkatanCreate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  // components: {
  //   DatePicker: () => import("@/apps/core/components/DatePicker.vue")
  // },
  data() {
    this.angkatanMdl = new Angkatan();
    this.objectMdl = this.angkatanMdl;  // alias yg digunakan di cuMixin
    let obv = this.angkatanMdl.getObservables();
    obv.month_names = MONTH_NAMES;
    return obv;
  },
  watch: {
    angkatan: {
      // angkatanMdl berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.angkatanMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>

/* eslint no-unused-vars: "error" */
// Digunakan oleh Create Update Views
import { has } from "lodash";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";


export default {
  mixins: [onlineCheckMixin],

  computed: {
    isCreate() {
      return !has(this.$route.params, "id");
    },
    isLoaded() {
      return this.objectMdl.isLoaded();
    },
    saveContext() {
      return this.isCreate ? "create" : "update";
    },
    isValid() {
      return this.validity.edited && this.validity.validated;
    }
  },

  methods: {
    setEdited(value) {
      this.objectMdl.clearNonFieldErrors();
      this.objectMdl.setEdited(value);
    },
    onSaved(respObj) {  // eslint-disable-line no-unused-vars
      this.$router.go(-1);
    },
    onLoaded(respObj) {  // eslint-disable-line no-unused-vars
      
    },
    saveObject() {
      this.objectMdl.save(this.saveContext, this.onSaved);
    },
    fetchData() {
      if (this.isCreate) return;
      this.objectMdl.load(this.$route.params.id, this.onLoaded);
    },
    validateInput(field) {
      this.objectMdl.validate(field);
    },
    cancel() {
      this.objectMdl.reset(); // apakah diperlukan ???
      this.$router.go(-1);
    }
  },

  created() {
    this.fetchData();
  },

  activated() {
    // diperlukan untuk kasus token expired, kemudian pindah
    // ke halaman login, dan karena mainapplayout-nya di-keep-alive
    // maka halaman tersebut masih aktif di-background, tanpa data.
    // setelah login kembali ke halaman tersebut dan perlu fetch ulang datanya.
    // contoh kasus: anggotadetail
    this.fetchData();
  }
}

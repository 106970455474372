import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "angkatan";
const modelPath = `/programstudi/${modelName}/`;

class Angkatan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      mulai: null
    };
    let requiredFields = ["mulai"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  getPayload() {
    let data = super.getPayload();
    const mulai = new Date(data.mulai);
    data.mulai = mulai.toLocaleDateString('id');
    return data;
  }
}

export default Angkatan;